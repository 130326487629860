import React, { useState, useEffect, useRef } from 'react';
import { getMethod2 } from '../functions/httpMethods';
import ReusableSelect from './general/ReusableSelect';
import { Grid } from '@mui/material';
import LoadingBackdrop from './general/LoadingBackdrop';

const EnhancedFilter = ({ label, endpoint, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const cache = useRef({});

  const fetchOptions = async () => {
   setLoading(true)

    await getMethod2(endpoint,'').then(response=>{
      console.log(response)
      
      cache.current[endpoint] = response;
      setOptions(response);
      setLoading(false)
    });
    
  };

  return (<> 
 <ReusableSelect
 loading={loading}
      onFocus={fetchOptions}
      type={ "filter"}
      label={label}
      width={200}
      marginRight={40}
      bgColor="#FFF"
      state={value}
      setState={onChange}
      options={options.map((o) => ({ value: o.filter_id, option: o.filter_name }))}
    />
   </>
    
  );
};

export default EnhancedFilter;
