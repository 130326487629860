import NunitoText from "../components/general/NunitoText";
import Widget from "../components/general/Widget";
import { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  Box,
  TableHead,
  Modal,
  Button,
} from "@mui/material";
import ReusableButton from "../components/general/ReusableButton";
import { FaCircleQuestion } from "react-icons/fa6";
import InstructionsModal from "../components/modal/InstructionsModal";
import ReusableSelect from "../components/general/ReusableSelect";
import {
  getLevelTasks,
  getTaskPossibleAnswers,
  getCompetitionLevels,
  getAnswerParticipants,
} from "../functions/getData";
import { postIdentifiedAnswerKeys } from "../functions/postData";
import { showNotification } from "../functions/snackbar";
import { useSnackbar } from "notistack";
import Loader from "../components/general/Loader";
import ConfirmIdentifiedAnswers from "../components/modal/ConfirmIdentifiedAnswers.jsx";
import { useLocation } from "react-router-dom";
import ShowUniqueAnswerParticipantsModal from "../components/modal/ShowUniqueAnswerParticipantsModal.jsx";
import ShowCorrectAnswerParticipantsModal from "../components/modal/ShowCorrectAnswerParticipantsModal.jsx";

export default function IdentifyUniqueAnswers() {
  document.title = "Identify Unique Correct Answers";
  const [competitionName, setCompetitionName] = useState("");
  const [levelName, setLevelname] = useState("");
  const [keyAnswer, setKeyAnswer] = useState("");
  const [search, setSearch] = useState("");
  const [data, setData] = useState();
  const [originalData, setOriginalData] = useState();
  const [insetructionsModal, setInsetructionsModal] = useState(false);
  const [level, setLevel] = useState("");
  const [allLevels, setAllLevels] = useState([]);
  const [question, setQuestion] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [answerId, setAnswerId] = useState();
  const [correctAnswerParticipants, setCorrectAnswerParticipants] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayStudentsListModal, setDisplayStudentsListModal] =
    useState(false);
  const [
    displayCorrectAnswerStudentsListModal,
    setDisplayCorrectAnswerStudentsListModal,
  ] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  let location = useLocation();

  useEffect(() => {
    getCompetitionLevels(`${location.search.slice(4)}`, signal).then((res) => {
      setCompetitionName(res.name);
      const levelsArray = res.levels.map((level) => ({
        value: level.id,
        option: level.name,
      }));
      setAllLevels(levelsArray);
    });
  }, []);

  useEffect(() => {
    if (location.search.slice(0, 4) === "?id=" && level) {
      setAllQuestions([]);
      setQuestion("");
      setKeyAnswer("");
      getLevelTasks(location.search.slice(4), level, signal).then((data) => {
        setAllQuestions(
          data.tasks.map(({ task_id, task_tag }) => ({
            value: task_id,
            option: task_tag,
          }))
        );
        setData();
        setOriginalData([]);
      });
    }
  }, [level]);

  const retrieveAnswer = () => {
    setLoading(true);
    getTaskPossibleAnswers(question, level, signal).then((possibleAnswers) => {
      setKeyAnswer(possibleAnswers[0]?.answer_key);
      const structuredData = possibleAnswers[0]?.possible_keys.map(
        ({ id, possible_key, status }) => ({
          id,
          name: possible_key,
          status: status === "approved" ? true : false,
        })
      );
      setData(structuredData ? [...structuredData] : []);
      setOriginalData(structuredData ? [...structuredData] : []);

      setLoading(false);
      if (
        structuredData &&
        structuredData.filter((answer) => answer.id === answerId).length > 0
      ) {
        setDisplayStudentsListModal(true);
      }
    });
  };

  const fetchAllData = (displayKeyAnswerParticipants) => {
    getTaskPossibleAnswers(question, level, signal).then((possibleAnswers) => {
      setCorrectAnswerParticipants(
        possibleAnswers[0].correct_answer_participants
      );
      setKeyAnswer(possibleAnswers[0]?.answer_key);
      const structuredData = possibleAnswers[0]?.possible_keys.map(
        ({ id, possible_key, status }) => ({
          id,
          name: possible_key,
          status: status === "approved" ? true : false,
        })
      );
      setData(structuredData ? [...structuredData] : []);
      setOriginalData(structuredData ? [...structuredData] : []);
      displayKeyAnswerParticipants &&
        setDisplayCorrectAnswerStudentsListModal(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    question && fetchAllData(false);
  }, [question]);

  const handleSearch = () => {};

  const handleSubmitIdentifications = () => {
    const updatedAnswers = data.filter((answer) => {
      const compareOriginalAnswer = originalData.filter((originalAnswer) => {
        return originalAnswer.id === answer.id;
      });
      return compareOriginalAnswer[0].status !== answer.status;
    });
    const submitAnswers = updatedAnswers.map(({ id, status }) => ({
      answer_id: id,
      status: status ? "approved" : "declined",
    }));
    setDisplayConfirmModal(false);
    setLoading(true);
    postIdentifiedAnswerKeys(submitAnswers).then((res) => {
      if (res.error) {
        showNotification("warning", res.error, enqueueSnackbar, closeSnackbar);
        setLoading(false);
      } else {
        // const tempQuestion = question;
        // setQuestion("");
        showNotification(
          "success",
          res.message,
          enqueueSnackbar,
          closeSnackbar
        );
        // setQuestion(tempQuestion);
        fetchAllData(false);
      }
      // setLoading(false);
    });
  };

  const handleAnswerClick = (_, clickedAnswerId) => {
    setAnswerId(clickedAnswerId);
    setDisplayStudentsListModal(true);
  };

  const handleKeyAnswerClick = () => {
    // setLoading(true)
    fetchAllData(true);
  };

  return (
    <Box className="wrapperBox">
      <Grid className="tableContainer" container>
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <NunitoText
            value="Identify Unique Answers"
            fontSize={40}
            fontWeight={700}
            italic
            color="#144A94"
          />
          <FaCircleQuestion
            style={{
              color: "#5E75C3",
              fontSize: "45px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setInsetructionsModal(true);
            }}
          />
        </Grid>
        <Grid
          className="firstRowContainer"
          container
          justifyContent="space-between"
        >
          <div className="dashboardAndSelfBtnDiv" style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                gap: 15,
                width: "100%",
              }}
            >
              <Widget content={competitionName} title="Competition" />
              {/* <Widget content={keyAnswer} title="Key Answer" /> */}
            </div>
          </div>
        </Grid>
        {loading ? (
          <Loader height={600} />
        ) : (
          <>
            <Box
              style={{
                marginTop: 10,
                marginBottom: 35,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                width: "100%",
                gap: 4,
              }}
            >
              <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3>Choose the level: </h3>
                  <ReusableSelect
                    type="type2"
                    state={level}
                    setState={setLevel}
                    btnBgColor="#5E75C3"
                    height={60}
                    width={300}
                    options={allLevels}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3>Choose the task:</h3>
                  <ReusableSelect
                    type="type2"
                    state={question}
                    setState={setQuestion}
                    btnBgColor="#5E75C3"
                    height={60}
                    width={450}
                    options={allQuestions}
                  />
                </Box>
              </Box>
              {data?.length > 0 && (
                <ReusableButton
                  text="Submit additional answer keys"
                  fontSize={15}
                  bgColor="#5E75C3"
                  height={46}
                  // marginRight={20}
                  onClick={() => setDisplayConfirmModal(true)}
                  iconType="save"
                />
              )}
            </Box>
            {/* <Widget content={keyAnswer} title="Key Answer" /> */}
            {keyAnswer && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  // flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  marginBottom: 3,
                }}
              >
                <NunitoText
                  value="Configured Answer Key:"
                  fontSize={32}
                  fontWeight={700}
                  italic
                  color="#144A94"
                />
                <ReusableButton
                  text={keyAnswer}
                  fontSize={18}
                  bgColor="#5E75C3"
                  onClick={handleKeyAnswerClick}
                />
              </Box>
            )}

            {data?.length > 0 ? (
              <AnswersTable
                tableData={data}
                setTableData={setData}
                handleAnswerClick={handleAnswerClick}
              />
            ) : (
              question &&
              data?.length === 0 && (
                <NunitoText
                  value={
                    "No unique answer found for this grade and question. Please upload the student answers to procced"
                  }
                  fontSize={28}
                  fontWeight={600}
                  // italic
                  color="red"
                />
              )
            )}
          </>
        )}
      </Grid>
      {insetructionsModal && (
        <InstructionsModal setModalState={setInsetructionsModal} />
      )}
      {displayConfirmModal && (
        <ConfirmIdentifiedAnswers
          confirmFunction={() => handleSubmitIdentifications()}
          cancelConfirm={() => setDisplayConfirmModal(false)}
        />
      )}

      <Modal
        open={displayStudentsListModal}
        onClose={() => setDisplayStudentsListModal(false)}
        // err={err}
      >
        <>
          {
            <ShowUniqueAnswerParticipantsModal
              answerText={
                originalData?.filter((answer) => answer.id === answerId)[0]
                  ?.name
              }
              answerId={answerId}
              competitionId={location.search.slice(4)}
              onClose={() => setDisplayStudentsListModal(false)}
              onConfirm={() => {
                setDisplayStudentsListModal(false);
                retrieveAnswer();
              }}
            />
          }
        </>
      </Modal>

      <Modal
        open={displayCorrectAnswerStudentsListModal}
        onClose={() => setDisplayCorrectAnswerStudentsListModal(false)}
        // err={err}
      >
        <>
          {
            <ShowCorrectAnswerParticipantsModal
              answerText={keyAnswer}
              onClose={() => setDisplayCorrectAnswerStudentsListModal(false)}
              correctAnswerParticipants={correctAnswerParticipants}
            />
          }
        </>
      </Modal>
    </Box>
  );
}

function AnswersTable({ tableData, setTableData, handleAnswerClick }) {
  const [allChecks, setAllChecks] = useState([]);

  const arrangeData = (data) => {
    const arrangedData = [];
    for (let index = 0; index < data.length; index += 3) {
      arrangedData.push([data[index], data[index + 1], data[index + 2]]);
    }
    return arrangedData;
  };

  const dataRows = arrangeData(tableData);

  const handleUpdateItem = (updatedObjectId) => {
    const updatedTableData = tableData.map((answer) => {
      if (answer.id === updatedObjectId) {
        return { ...answer, status: !answer.status };
      }
      return answer;
    });
    setTableData(updatedTableData);
  };

  useEffect(() => {
    let tempAllCheck = [];
    if (tableData.length >= 3) {
      tempAllCheck = [
        { id: 0, status: true },
        { id: 1, status: true },
        { id: 2, status: true },
      ];
    } else if (tableData.length === 2) {
      tempAllCheck = [
        { id: 0, status: true },
        { id: 1, status: true },
      ];
    } else {
      tempAllCheck = [{ id: 0, status: true }];
    }
    for (let index = 0; index < tableData.length; index++) {
      if (tableData[index].status === false && tableData[index].name !== "") {
        tempAllCheck[index % 3] = { id: index % 3, status: false };
      }
    }
    setAllChecks(tempAllCheck);
  }, [tableData]);

  return (
    <TableContainer component={Paper} elevation={5}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <AnswerTableHeader
          allChecks={allChecks}
          setAllChecks={setAllChecks}
          tableData={tableData}
          setTableData={setTableData}
        />
        <AnswerTableBody
          rows={dataRows}
          updateItem={handleUpdateItem}
          handleAnswerClick={handleAnswerClick}
        />
      </Table>
      {/* <TablePagination
        // rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataRows.length}
        rowsPerPage={5}
        page={1}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      /> */}
    </TableContainer>
  );
}

const AnswerTableHeader = ({
  allChecks,
  setAllChecks,
  tableData,
  setTableData,
}) => {
  const handleUpdateCheckAll = (_, updatedCheck) => {
    const updatedAllChecks = allChecks.map((check) => {
      if (check.id === updatedCheck.id) {
        return { ...check, status: !check.status };
      }
      return check;
    });
    const updatedTableData = tableData.map((data, index) => {
      if (index % 3 === updatedCheck.id && data.name !== "") {
        return { ...data, status: !updatedCheck.status };
      }
      return data; // For other objects, return them unchanged
    });
    setAllChecks(updatedAllChecks);
    setTableData(updatedTableData);
  };

  return (
    <TableHead>
      <TableRow>
        {allChecks.map((check) => {
          return (
            <>
              <TableCell
                padding="checkbox"
                onClick={(event) => handleUpdateCheckAll(event, check)}
              >
                <Checkbox style={{ color: "#000000" }} checked={check.status} />
              </TableCell>
              <TableCell
                style={{
                  fontSize: 20,
                  fontWeight: "bolder",
                  color: "#144A94",
                  height: 61,
                  backgroundColor: "#FFF",
                  backgroundClip: "padding-box",
                }}
              >
                Unique Student Answers
              </TableCell>
            </>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const AnswerTableBody = ({ rows, updateItem, handleAnswerClick }) => {
  const hanldeUpdateStatus = (e, dataItemId) => {
    updateItem(dataItemId);
  };
  return (
    <TableBody>
      {rows.map((row, index) => {
        return (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child td": { border: 0 } }}
          >
            <TableCell padding="checkbox">
              <Checkbox
                onClick={(event) => hanldeUpdateStatus(event, row[0].id)}
                checked={row[0].status}
                style={{ color: row[0].name === "" ? "red" : "#000000" }}
                disabled={row[0].name === "" ? true : false}
              />
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              onClick={(event) => handleAnswerClick(event, row[0].id)}
            >
              <Button
                style={{
                  color: "black",
                  width: "100%",
                  justifyContent: "flex-start",
                  minHeight: 25,
                  textTransform: "none",
                }}
              >
                {row[0].name}
              </Button>
            </TableCell>
            {row[1] && (
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => hanldeUpdateStatus(event, row[1].id)}
                  checked={row[1].status}
                  style={{ color: row[1].name === "" ? "red" : "#000000" }}
                  disabled={row[1].name === "" ? true : false}
                />
              </TableCell>
            )}
            <TableCell
              component="td"
              scope="row"
              onClick={(event) => handleAnswerClick(event, row[1].id)}
            >
              <Button
                style={{
                  color: "black",
                  width: "100%",
                  justifyContent: "flex-start",
                  minHeight: 25,
                  textTransform: "none",
                }}
              >
                {row[1]?.name}
              </Button>
            </TableCell>
            {row[2] && (
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => hanldeUpdateStatus(event, row[2].id)}
                  checked={row[2].status}
                  style={{ color: row[2].name === "" ? "red" : "#000000" }}
                  disabled={row[2].name === "" ? true : false}
                />
              </TableCell>
            )}
            <TableCell
              component="td"
              scope="row"
              onClick={(event) => handleAnswerClick(event, row[2].id)}
            >
              <Button
                style={{
                  color: "black",
                  width: "100%",
                  justifyContent: "flex-start",
                  minHeight: 25,
                  textTransform: "none",
                }}
              >
                {row[2]?.name}
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};
