import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
// import FileCopyIcon from '@mui/icons-material/FileCopy'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BuildIcon from "@mui/icons-material/Build";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import CheckIcon from "@mui/icons-material/Check";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import LanguageIcon from "@mui/icons-material/Language";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MdOutlineCancel, MdOutlineFindInPage } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { FiEdit, FiPlus } from "react-icons/fi";
import { IoPersonRemove } from "react-icons/io5";
import { RiDeleteBin5Line, RiFindReplaceFill } from "react-icons/ri";
import { IoPersonAdd } from "react-icons/io5";
import {
  BsCheckCircle,
  BsCardList,
  BsListTask,
  BsClipboardCheck,
} from "react-icons/bs";
import { IoIosCopy } from "react-icons/io";
import {
  FaUserSlash,
  FaUserCheck,
  FaWindowClose,
  FaCloudUploadAlt,
  FaPen,
} from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { getDateDiff } from "../../functions/general";
import {
  isAdmin,
  isAdminOrPartner,
  isAdminOrPartnerOrAssistant,
  isAssistant,
  isPartner,
  isPartnerOrAssistant,
} from "../../functions/checkrole";
import LightTooltip from "../general/LightTooltip";
import { getParticipantsReport, getReport } from "../../functions/getData";
import { useHistory } from "react-router-dom";
import { VerifiedSharp } from "@mui/icons-material";
import { HiMiniViewfinderCircle } from "react-icons/hi2";

const BtnLinks = (link, icon, blank) => (
  <Link
    style={{ color: "#000", height: 24 }}
    to={link}
    target={blank && "_blank"}
  >
    {icon}
  </Link>
);
export default function ActionButtons({
  setOnVerfiy,
  setOnDups,
  title,
  row,
  collection,
  section,
  taskInSection,
  checkTask,
  getters,
  setIssingle,
  setOnReasons,
  setId,
  setOnDelete,
  setOnApprove,
  setOnReject,
  setOnRestore,
  setOnEdit,
  setOnModerateLanguages,
  setOnManagePermission,
  setOnAddUser,
  setOnAddParticipant,
  setOnEnable,
  setOnDisable,
  setOnAdd,
  setOnUpload,
  setPartcipient,
  setOnEditParticipantRanking,
  setConfirmMarking,
  setAbsentParticipant,
}) {
  const openRowModal = (id, setOpenModal) => {
    setOpenModal(true);
    setId(id);
  };
  let history = useHistory();
  // Dev
  // const url = 'https://api.dev.simccms.org/api'
  // Staging

  // const url = 'https://api.stag.simccms.org/api'
  // Debug
  // const url = 'https://api.debug.simccms.org/api'

  // Production
  const url = "https://api.simccms.org/api";
  const user = useSelector((state) => state.user);
  const controller = new AbortController();
  const signal = controller.signal;
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  //   const exportToJson = e => {
  //     e.preventDefault()
  //     downloadFile({
  //       data: JSON.stringify(usersData.users),
  //       fileName: 'users.json',
  //       fileType: 'text/json',
  //     })
  //   }

  const exportToCsv = (e, data, compname) => {
    console.log(data);
    // Headers for each column
    let headers = [
      "competition,organization,country,level,grade,school,tuition,index,participant,certificate number,points,award,country_rank,school_rank,global_rank",
    ];
    // Convert users data to a csv
    console.log(data, "ad");
    data.data.data.shift();
    let usersCsv = data.reduce((acc, user) => {
      const {
        competition,
        organization,
        country,
        level,
        grade,
        school,
        tuition_centre,
        index_no,
        name,
        certificate_no,
        points,
        award,
        country_rank,
        school_rank,
        global_rank,
      } = user;
      acc.push(
        [
          competition,
          organization,
          country,
          level,
          grade,
          school,
          tuition_centre,
          index_no,
          name,
          certificate_no,
          points,
          award,
          country_rank,
          school_rank,
          global_rank,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: `${compname}.csv`,
      fileType: "text/csv",
    });
  };
  let icons = [];
  const setpart = async (res) => {
    localStorage.setItem("report", JSON.stringify(res));

    await setPartcipient(res);
  };
  switch (title) {
    case "schools":
      icons = [
        // Need add in a list of user.allowed when isPartnerOrAssistant()
        row.status !== "deleted" && {
          title: "Edit",
          item: <FiEdit />,
          fn: () => openRowModal(row.id, setOnEdit),
        },
        ((row.status !== "deleted" && isAdmin()) ||
          (["pending", "rejected"].includes(row.status) &&
            isPartnerOrAssistant())) && {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        isAdmin() &&
          row.status === "deleted" && {
            title: "Restore",
            item: <RestoreFromTrashIcon />,
            fn: () => openRowModal(row.id, setOnRestore),
          },
        isAdminOrPartnerOrAssistant() &&
          row.status === "pending" &&
          user.user_id !== row.created_by_userid &&
          row.user_can_approve_and_reject && {
            title: "Approve",
            item: <CheckIcon />,
            fn: () => openRowModal(row.id, setOnApprove),
          },
        row.status === "active" &&
          isAdminOrPartnerOrAssistant() && {
            title: "Add Teacher",
            item: <AddIcon />,
            fn: () => openRowModal(row.id, setOnAddUser),
          },
        row.status === "active" && {
          title: "Add Participant",
          item: <IoPersonAdd />,
          fn: () => openRowModal(row.id, setOnAddParticipant),
        },
        isAdminOrPartnerOrAssistant() &&
          row.status === "pending" &&
          user.user_id !== row.created_by_userid &&
          row.user_can_approve_and_reject && {
            title: "Reject",
            item: <FaWindowClose />,
            fn: () => openRowModal(row.id, setOnReject),
          },
        // isAdminOrPartner()&&(row.status==='pending'||row.status==='rejected')&&{title:'Approve',item:<CheckCircleIcon />,fn:()=>{
        //     setOnApprove(true)

        //     setId(row.id)
        // }},
        // isPartner()&&(row.status==='pending')&&{title:'Reject',item:<MdOutlineCancel color='rgb(232, 48, 66)'/>,fn:()=>{
        //     setOnReject(true)
        //     setId(row.id)
        // }}
        // row.status !== 'Pending' && { title: 'Merge', item: <MergeTypeIcon onClick={() => setOnMerge(true)} /> }
      ];
      break;
    case "participants":
      icons = [
        {
          title: "Edit",
          item: <FiEdit />,
          fn: () => openRowModal(row.id, setOnEdit),
        },
        (isAdmin() ||
          (!isAdmin() &&
            getDateDiff(new Date(), new Date(row.created_at)) <= 7)) && {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        row.status === "result computed" && {
          title: "report",
          item: <HiDocumentReport />,
          fn: () => {
            // console.log(row.index_no , row.certificate_no)
            // getParticipantsReport(row.index_no , row.certificate_no , '',signal).then((res)=>{
            //     if(res.general_data){
            //             setpart(res).then(()=>{
            //                 history.push("/indevidalStudent")

            //             })
            //     }
            // })
            window.open(
              `${url}/participant/report/by-certificate?index_no=${String(
                row.index_no
              )}&certificate_no=${String(row.certificate_no)}&as_pdf=${1}`,
              "_blank",
              "noreferrer"
            );
          },
        },
        // { title: 'Duplicate', item: <IoIosCopy /> }, { title: 'Edit Answers', item: <EditIcon /> }
      ];
      break;
    case "difficulty":
    case "domain":
      icons = [
        {
          title: "Edit",
          item: <FiEdit />,
          fn: () => openRowModal(row.id, setOnEdit),
        },
        {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        row.status !== "active" && { title: "Approve", item: <CheckIcon /> },
      ];
      break;
    case "task":
    case "translation":
      icons = [
        {
          title: "Edit",
          item: <FiEdit />,
          fn: () => window.open(`/edittask?id=${row.id}`),
        },
        row.allow_delete && {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        {
          title: "Duplicate",
          item: <IoIosCopy />,
          fn: () => openRowModal(row.id, setOnDups),
        },
        // { title: 'Verify', item: <CheckCircleIcon /> },
        // { title: 'Assign Permission', item: <BuildIcon /> },
        {
          title: "Moderate Languages",
          item: <LanguageIcon />,
          fn: () => openRowModal(row.id, setOnModerateLanguages),
        },
        // {
        //     title: 'Translate Task', item: <Link to={`/translateTasks/${row[getters[0]]}`} style={{ color: '#000' }}>
        //         <img src={languageIcon} alt='Language Icon' /></Link>
        // }
      ];
      break;
    case "competitions":
      icons = [
        isAdminOrPartner() &&
          row.status !== "closed" && {
            title: "Edit",
            item: BtnLinks(`editcompetition?id=${row.id}`, <FiEdit />),
          },
        isAdmin() && {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        isAssistant() &&
          row.status !== "closed" && {
            title: "View Competition Details",
            item: BtnLinks(`/viewcompetition?id=${row.id}`, <VisibilityIcon />),
          },
        // { title: 'Session', item: <AccountBoxOutlinedIcon /> },
        isAdmin() && {
          title: "Marking Preparation",
          item: BtnLinks(`/markingpreparation?id=${row.id}`, <EditIcon />),
        },
        isAdmin() && {
          title: "Verify Collections",
          item: BtnLinks(
            `/compCollections?id=${row.id}`,
            <CheckCircleIcon
              style={{ color: row.is_verified === 1 ? "green" : "black" }}
            />
          ),
        },

        isAdmin() &&
          new Date(row.competition_end_date).getTime() >
            new Date().getTime() && {
            title: "Add Country Partner",
            item: <AddIcon />,
            fn: () => openRowModal(row.id, setOnAddUser),
          },
        isAdmin() &&
          !!row.rounds.length && {
            title: "Upload Answers",
            item: (
              <Link to={`/uploadStudentAnswer?id=${row.id}`}>
                <FaCloudUploadAlt />
              </Link>
            ),
            fn: () => {},
          },
        isAdmin() &&
          row.is_verified === 1 && {
            title: "Identify Unique Answers",
            item: BtnLinks(
              `/identifyUniqueAnswers?id=${row.id}`,
              <HiMiniViewfinderCircle />
            ),
          },
        isAdmin() && {
          title: "Integrity Check",
          item: BtnLinks(`/cheatinglist?id=${row.id}`, <BsListTask />),
        },
        isAdmin() &&
          row.is_verified === 1 && {
            title: "Marking",
            item: BtnLinks(`/markingoverview?id=${row.id}`, <FaPen />),
          },
        // row.compute_status==='Finished'&&isAdmin()&&{title: 'Generate Report' , item :<HiDocumentReport/> , fn:
        //    (e)=> getReport(Number(row.id)  ,'',signal ).then(res=>{
        //     console.log(res , 'report res')
        //     exportToCsv(e , res , row.name )
        //     })
        // },
        row.compute_status === "Finished" &&
          isAdmin() && {
            title: "Show Report",
            item: BtnLinks(`/compReport?id=${row.id}`, <GrView />),
          },

        // { title: 'View Tasks', item: <FaTasks /> },
        // { title: 'View Collections', item: <CollectionsBookmarkIcon /> }
      ];
      break;
    case "pending partners competition date":
      icons = [
        {
          title: "Approve",
          item: <CheckIcon />,
          fn: () => openRowModal(row.id, setOnApprove),
        },
        {
          title: "Reject",
          item: <FaWindowClose />,
          fn: () => openRowModal(row.id, setOnReject),
        },
      ];
      break;
    case "taskModeration":
      icons = [
        {
          title: "Moderate Task",
          item: (
            <Link
              to={{ pathname: `/moderateTask`, state: [row[getters[0]]] }}
              style={{ color: "#000" }}
            >
              {" "}
              <SpellcheckIcon />
            </Link>
          ),
        },
      ];
      break;
    case "roles":
      icons = [
        {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
      ];
      break;
    case "collection":
      icons = [
        {
          title: "Edit",
          item: BtnLinks(`/editcollection?id=${row.id}`, <FiEdit />),
        },
        row.allow_delete && {
          title: "Delete",
          item: <DeleteIcon />,
          fn: () => openRowModal(row.id, setOnDelete),
        },
        {
          title: "Duplicate",
          item: <IoIosCopy />,
          fn: () => openRowModal(row.id, setOnDups),
        },
        {
          title: "Assign Permision",
          item: <BuildIcon />,
          fn: () => openRowModal(row.id, setOnManagePermission),
        },
        row["status"] === "pending" && {
          title: "Approve",
          item: <CheckIcon />,
        },
        row["status"] !== "verified" && {
          title: "Verify",
          item: <CheckCircleIcon />,
          fn: () => openRowModal(row.id, setOnVerfiy),
        },
        // {
        //     title:'View Tasks' , item:BtnLinks(`/task?id=${row.id}`,<BsListTask/>,true)
        // }
      ];
      break;
    case "compCollection":
      icons = [
        {
          title: "verfiy collection",
          item: BtnLinks(
            `/editcollection?id=${row.id}&compid=${row.competition_id}&ver`,
            <CheckCircleIcon
              style={{ color: row.collection_verified ? "green" : "" }}
            />
          ),
        },

        {
          title: "verfiy difficulty and points",
          item: row.collection_verified ? (
            BtnLinks(
              `/verfiyassign?competition_id=${row.competition_id}&round_id=${row.round_id}&level_id=${row.level_id}&collection_id=${row.id}`,
              <BsClipboardCheck
                style={{
                  color:
                    row.collection_verified &&
                    !row.difficulty_and_points_verified
                      ? "black"
                      : row.collection_verified &&
                        row.difficulty_and_points_verified
                      ? "green"
                      : "red",
                  cursor: !row.collection_verified ? "not-allowed" : "",
                }}
              />
            )
          ) : (
            <BsClipboardCheck
              style={{
                color:
                  row.collection_verified && !row.difficulty_and_points_verified
                    ? "black"
                    : row.difficulty_and_points_verified
                    ? "green"
                    : "red",
                cursor: !row.collection_verified ? "not-allowed" : "",
              }}
            />
          ),
        },
      ];
      break;
    case "users":
      let confirmCan = true;
      switch (user.role_id) {
        case 0:
          confirmCan = [0, 1, 2, 3, 4, 5].includes(row.role_id);
          break;
        case 1:
          confirmCan = [1, 2, 3, 4, 5].includes(row.role_id);
          break;
        case 2:
          confirmCan = [3, 4, 5].includes(row.role_id);
          break;
        case 3:
          confirmCan = false;
          break;
        case 4:
          confirmCan = [3, 5].includes(row.role_id);
          break;
        case 5:
          confirmCan = [3].includes(row.role_id);
          break;
        default:
      }
      let canEditOtherUser =
        (user.user_id === 1 || confirmCan || user.user_id === row.id) &&
        row.status !== "deleted";
      icons = canEditOtherUser
        ? [
            {
              title: "Edit User",
              item: <FiEdit />,
              fn: () => openRowModal(row.id, setOnEdit),
            },
            isAdmin() && {
              title: "Delete",
              item: <DeleteIcon />,
              fn: () => openRowModal(row.id, setOnDelete),
            },
            row["status"] === "active" && isAdmin()
              ? {
                  title: "Disable User",
                  item: <FaUserSlash />,
                  fn: () => openRowModal(row.id, setOnDisable),
                }
              : row.status === "disabled" && isAdmin()
              ? {
                  title: "Enable User",
                  item: <FaUserCheck />,
                  fn: () => openRowModal(row.id, setOnEnable),
                }
              : null,
            // isAdmin() && { title: 'View User Permissions', item: <Link to={`/userpermissions/${row.id}`} style={{ color: '#000' }}><VisibilityIcon /></Link> }
          ]
        : [];
      break;
    case "add task to collection":
      icons = [
        checkTask(collection, row.id) === -1
          ? {
              title: "Add to section",
              item: <FiPlus />,
              fn: () => openRowModal(row.id, setOnAdd),
            }
          : taskInSection(section, row.id) === -1
          ? null
          : {
              title: "Delete",
              item: <DeleteIcon />,
              fn: () => openRowModal(row.id, setOnDelete),
            },
      ];
      break;
    case "organization":
      icons =
        row.status === "active"
          ? [
              {
                title: "Edit",
                item: <FiEdit />,
                fn: () => openRowModal(row.id, setOnEdit),
              },
              {
                title: "Delete",
                item: <DeleteIcon />,
                fn: () => openRowModal(row.id, setOnDelete),
              },
            ]
          : [];
      break;
    case "collections":
      icons = [
        {
          title: "Verfiy",
          item: <CheckCircleIcon />,
          disabled: false,
          fn: () => openRowModal(row.id, setOnVerfiy),
        },
        row.status !== "Rejected" && {
          title: "Reject",
          item: <MdOutlineCancel color="rgb(232, 48, 66)" />,
          fn: () => {
            setOnReject(true);
            setId(row.id);
          },
        },
        {
          title: "View",
          item: BtnLinks(`/editcollection?id=${row.id}&mod`, <GrView />),
        },
        row.reject_reasons.length > 0 && {
          title: "rejection reasons",
          item: <BsCardList />,
          fn: () => {
            setOnReasons(true);
            setId(row.id);
          },
        },
        true && {
          title: "Delete",
          item: <RiDeleteBin5Line />,
          fn: () => {
            setIssingle(true);
            setId(row.id);
            setOnDelete(true);
          },
        },
        {
          title: "View Tasks",
          item: BtnLinks(`/tasks?id=${row.id}`, <BsListTask />, true),
        },
      ];
      break;
    case "tasks":
      icons = [
        {
          title: "Verfiy",
          item: <CheckCircleIcon />,
          disabled: false,
          fn: () => {
            setOnApprove(true);
            setIssingle(true);
            setId(row.id);
          },
        },
        row.status !== "Rejected" && {
          title: "Reject",
          item: <MdOutlineCancel color="rgb(232, 48, 66)" />,
          fn: () => {
            setOnReject(true);
            setId(row.id);
          },
        },
        {
          title: "View",
          item: BtnLinks(`/edittask?id=${row.id}&mod`, <GrView />),
        },
        row.reject_reasons.length > 0 && {
          title: "rejection reasons",
          item: <BsCardList />,
          fn: () => {
            setOnReasons(true);
            setId(row.id);
          },
        },
        true && {
          title: "Delete",
          item: <RiDeleteBin5Line />,
          fn: () => {
            setIssingle(true);
            setId(row.id);
            setOnDelete(true);
          },
        },
      ];
      break;
    case "competition Report":
      icons = [
        {
          title: "Edit Ranking",
          item: <FiEdit />,
          // fn: () => openRowModal(row.id, setOnEdit),
          fn: () => openRowModal(row, setOnEditParticipantRanking),
        },
        {
          title: "Mark Participant",
          item: <CheckCircleIcon />,
          fn: () => openRowModal(row, setConfirmMarking),
        },
        {
          title: "Mark as Absent",
          item: <IoPersonRemove />,
          fn: () => openRowModal(row, setAbsentParticipant),
        },
      ];
      break;
    default:
  }
  return (
    <div
      style={{
        overflow: "scroll",
        scrollbarWidth: "none",
        scrollbarWidth: "none",
      }}
    >
      {icons.map(
        (icon, index) =>
          icon && (
            <LightTooltip
              key={`${row.id}-${index}`}
              title={icon.title}
              type="Button"
              rollover={0}
              topic={0}
              hide={0}
            >
              <IconButton
                disabled={icon.disabled}
                onClick={() => icon.fn && icon.fn()}
                style={{ color: "#000" }}
              >
                {icon.item}
              </IconButton>
            </LightTooltip>
          )
      )}
    </div>
  );
}
