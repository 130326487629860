import { resolve } from "path";
import { postMethod } from "./httpMethods";
import { reject } from "q";
// School
export const addSchool = (data, signal) => {
  return new Promise((resolve, reject) => {
    postMethod("school", data, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Organization
export const addOrganization = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("organizations", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// User
export const addUser = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("user", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Participant
export const addParticipant = (data, signal) => {
  return new Promise((resolve, reject) => {
    postMethod("participant", data, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Upload Participant Answers
export const uploadParticipantAnswers = (data, signal) => {
  return new Promise((resolve, reject) => {
    postMethod("competition/upload_answers", data, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Domain and Tag
export const addDomain = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("tag", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Competition
export const addCompetition = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("competition", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addCompetitionOrganization = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("competition/organization", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addCompetitionRound = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("competition/rounds", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addCompetitionRoundAward = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("competition/awards", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addCompetitionOverallAward = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("competition/overall_awards", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addMarkingPreparation = (params, signal) => {
  return new Promise((resolve, reject) => {
    postMethod("marking/preparation", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Task
export const addTask = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("tasks", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Difficulty Group
export const addDifficultyGroup = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("taskdifficultygroup", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Collection
export const addCollection = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("collection", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//addsection
export const addsection = (data) => {
  return new Promise((resolve, reject) => {
    postMethod("collection/sections", data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const addGroup = (id, data) => {
  console.log(id, data);
  return new Promise((resolve, reject) => {
    postMethod(`marking/preparation/${id}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//approve tasks
export const approveTask = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`tasks/approve`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//reject tasks
export const rejectTask = (id, data) => {
  return new Promise((resolve, reject) => {
    postMethod(`tasks/reject/${id}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//approve collection
export const approveCollection = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`collection/approve`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//reject collection
export const rejectCollection = (id, data) => {
  console.log("should be here ");
  return new Promise((resolve, reject) => {
    postMethod(`collection/reject/${id}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//verfiy collection
export const verfiycollection = (id) => {
  return new Promise((resolve, reject) => {
    postMethod(`collection/verify/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//duplicateTasks
export const duplicatetasks = (id) => {
  return new Promise((resolve, reject) => {
    postMethod(`tasks/duplicate/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//duplicateCollections
export const duplicateCollections = (id) => {
  return new Promise((resolve, reject) => {
    postMethod(`collection/duplicate/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//award_stats
export const AwardStats = (id, data) => {
  return new Promise((resolve, reject) => {
    postMethod(`marking/competition/groups/award_statics/compute/${id}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const AwardStatsPrecent = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`marking/competition/groups/award_statics/check_progress`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const verfiyCollection = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`collection/verify`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const verfiyTask = (id, data) => {
  return new Promise((resolve, reject) => {
    postMethod(`tasks/verify/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const verfiyDiffPoints = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`collection/difficultyandpoints/verify`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const refreshData = (id) => {
  return new Promise((resolve, reject) => {
    postMethod(`marking/refresh/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const elemParticipants = (id, data, param) => {
  return new Promise((resolve, reject) => {
    console.log(`participant/eliminate/${id}${param}`);
    postMethod(`participant/eliminate/${id}${param}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const postIdentifiedAnswerKeys = (data) => {
  return new Promise((resolve, reject) => {
    postMethod(`marking/possible_similar_answers`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const confirmedCountries = (id, data) => {
  return new Promise((resolve, reject) => {
    postMethod(`competition/cheaters/confirmed-countries/${id}`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

// {domain}}/marking/possible_similar_answers/update_answer
export const updateParticipantsAnswers = (data) => {
  console.log(data);
  return new Promise((resolve, reject) => {
    postMethod(`marking/possible_similar_answers/update_answer`, data)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const MarkParticipant = (id) => {
  console.log(id);
  return new Promise((resolve, reject) => {
    postMethod(`marking/participant/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const MakeAbsentParticipant = (id) => {
  return new Promise((resolve, reject) => {
    postMethod(`participant/mark-absent/${id}`)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
