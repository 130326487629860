import React from "react";
import { Box, Grid } from "@mui/material";
import { makeSureIsArray } from "../../functions/general";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
export default function ConfirmAbsentParticipant({
  table,
  id,
  selected,
  confirmFunction,
  cancelConfirm,
}) {
  const selectedRows =
    selected[0].id !== undefined
      ? makeSureIsArray(table.data).filter((s) =>
          id === "" ? selected.includes(s.id) : s.id === id
        )
      : makeSureIsArray(table.data).filter((s) =>
          id === "" ? selected.includes(s.index_no) : s.index_no === id
        );
  console.log(selectedRows);
  return (
    <Box className="popUpModal" style={{ width: 705, minHeight: 310 }}>
      <NunitoText
        value="Absent Student"
        fontSize={30}
        fontWeight={600}
        color="#F16774"
        marginTop={30}
      />
      <NunitoText
        value={`Are you sure you want to make`}
        fontSize={20}
        fontWeight={600}
      />
      {selectedRows.map((s, i) => (
        <NunitoText
          key={i}
          value={`${s.name}${i < selectedRows.length - 1 && ", "}`}
          fontSize={20}
          fontWeight={700}
        />
      ))}
      <NunitoText
        value={`as absent participant?`}
        fontSize={20}
        fontWeight={600}
      />
      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelConfirm()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => confirmFunction()}
        />
      </Grid>
    </Box>
  );
}
